<template>
  <form>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Country <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-autocomplete
              v-model="form.countryId"
              :items="countries"
              outlined
              dense
              flat
              background-color="white"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Name <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-text-field
              v-model="form.name"
              :error-messages="nameErrors"
              required
              outlined
              dense
              flat
              background-color="white"
              @input="$v.form.name.$touch()"
              @blur="$v.form.name.$touch()"
              ref="name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Code <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-text-field
              v-model="form.code"
              :error-messages="codeErrors"
              :counter="5"
              required
              outlined
              dense
              flat
              background-color="white"
              @input="$v.form.code.$touch()"
              @blur="$v.form.code.$touch()"
              ref="code"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Symbol <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-text-field
              v-model="form.symbol"
              :error-messages="symbolErrors"
              required
              outlined
              dense
              flat
              background-color="white"
              @input="$v.form.symbol.$touch()"
              @blur="$v.form.symbol.$touch()"
              ref="symbol"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Buy Price (Rupiah)</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-text-field
              v-model="form.toIDR"
              type="number"
              outlined
              dense
              flat
              background-color="white"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Middle Price (Rupiah)</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-text-field
              v-model="form.middlePrice"
              type="number"
              outlined
              dense
              flat
              background-color="white"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Sell Price (Rupiah)</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-text-field
              v-model="form.sellPrice"
              type="number"
              outlined
              dense
              flat
              background-color="white"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Bank Reference</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-select
              v-model="form.bankReference"
              :items="banks"
              outlined
              dense
              flat
              background-color="white"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" cols="12">
        <v-btn class="mr-4" @click="submit" color="primary">
          submit
        </v-btn>
        <v-btn @click="clear">
          clear
        </v-btn>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  mixins: [validationMixin],

  validations: {
    form: {
      name: { required },
      code: { required, maxLength: maxLength(5) },
      symbol: { required },
    },
  },

  data: () => ({
    defaultForm: {
      countryId: null,
      name: "",
      code: "",
      symbol: "",
      toIDR: 0,
      middlePrice: 0,
      sellPrice: 0,
      bankReference: "BCA",
    },
    form: {},
    banks: ["BCA", "BNI", "BRI", "MANDIRI", "BTPN", "PANIN", "BUKOPIN", "CIMB"],
  }),

  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("Name is required");
      return errors;
    },
    codeErrors() {
      const errors = [];
      if (!this.$v.form.code.$dirty) return errors;
      !this.$v.form.code.maxLength && errors.push("Code must be at most 5 characters long");
      !this.$v.form.code.required && errors.push("Code is required.");
      return errors;
    },
    symbolErrors() {
      const errors = [];
      if (!this.$v.form.symbol.$dirty) return errors;
      !this.$v.form.symbol.required && errors.push("Symbol is required");
      return errors;
    },
    ...mapState("country", ["countries"]),
  },

  methods: {
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        for (let key in Object.keys(this.$v.form)) {
          const input = Object.keys(this.$v.form)[key];
          if (input.includes("$")) return false;
          if (this.$v.form[input].$error) {
            this.$refs[input].focus();
            break;
          }
        }
      } else {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("currency/create", this.form)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    clear() {
      this.$v.form.$reset();
      this.form = Object.assign({}, this.defaultForm);
    },
    async dropDownCountry() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("country/getAll")
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },

  mounted() {
    this.form = Object.assign({}, this.defaultForm);
    this.dropDownCountry();
  },
};
</script>

<style></style>
